import React from 'react';
import { Container, Button } from 'react-bootstrap';
import {observer} from "mobx-react-lite";
import ProductStore from "../../../stores/ProductStore";
import ProductsList from "./ProductsList";
import ProductCreateModal from "./ProductCreateModal";
import AccountingCalculatorModal from "./AccountingCalculatorModal";
import AccountingCalculatorStatisticsModal
    from "./AccountingCalculatorStatisticsModal";


const ProductsPage = observer(({ }) => {
    return (
        <Container>
            <h1>Products</h1>
            <Button variant="primary" onClick={() => {
                ProductStore.setShowModal('addProduct', true);
            }}>Add Product</Button>
            <Button variant="primary" onClick={() => {
                ProductStore.updateAnalytics();
            }}>Update Analytics</Button>
            <Button variant="primary" onClick={() => {
                ProductStore.setShowModal('accountingCalculator', true);
            }}>Accounting Calculator</Button>
            <Button variant="primary" onClick={() => {
                ProductStore.setShowModal('accountingCalculatorStatistics', true);
            }}>Accounting Calculator Statistics</Button>
            <Button variant="primary" onClick={() => {
                ProductStore.fetchExportProductsXLSX();
            }
            }>Export Products XLSX</Button>

            {ProductStore.showModals.addProduct && <ProductCreateModal />}

            {ProductStore.showModals.accountingCalculator && <AccountingCalculatorModal />}

            {ProductStore.showModals.accountingCalculatorStatistics && <AccountingCalculatorStatisticsModal />}

            <ProductsList />

        </Container>
    );
});

export default ProductsPage;