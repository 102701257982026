import {Table} from "react-bootstrap";
import ProductStore from "../../../stores/ProductStore";
import {observer} from "mobx-react-lite";
import AnalyticsRow from "./AnalyticsRow";

const AnalyticsList = observer(({}) => {
    return (
        <div>
            <Table striped bordered hover>
                <thead>
                <tr>
                    <th>Internal Name</th>
                    <th>Quantity on stock</th>
                    <th>Average sales per month</th>
                    <th>Average sales per day</th>
                    <th>Enough for days</th>
                    <th>Enough for months</th>
                    <th>Missing 5 months</th>
                    <th>White supply quantity</th>
                    <th>Gray supply quantity</th>
                    <th>Comment</th>
                </tr>
                </thead>
                <tbody>
                {ProductStore.products.map(product => (
                    <AnalyticsRow key={product.id} product={product}/>
                ))}
                </tbody>
            </Table>
        </div>
    );
});

export default AnalyticsList;