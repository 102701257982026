import React, {useEffect, useState} from 'react';
import {Modal, Button, Form} from 'react-bootstrap';
import Select from 'react-select';
import {observer} from "mobx-react-lite";
import AccountStore from "../../../../stores/AccountStore";
import TransactionStore from "../../../../stores/TransactionStore";

const CreateTransactionTemplateModal = observer(({}) => {
    const [type, setType] = useState(0);
    const [fromElement, setFromElement] = useState(0);
    const [toElement, setToElement] = useState(0);
    const [fromTemplate, setFromTemplate] = useState(false);
    const [toTemplate, setToTemplate] = useState(false);
    const [name, setName] = useState('');
    const [formula, setFormula] = useState('amount');
    const [description, setDescription] = useState('');

    const accountOptions = AccountStore.accounts.map((a) => ({
        value: a.id,
        label: `${a.number}| ${a.name}`
    }));

    const templateOptions = TransactionStore.transactionTemplates.map((t) => ({
        value: t.id,
        label: 'T| ' + t.name
    }));

    const concatOptions = accountOptions.concat(templateOptions);

    const checkTemplate = (option) => {
        return option.label.startsWith('T| ');

    }

    const transactionTypeOptions = TransactionStore.transactionTypes.map((t) => ({
        value: t.id,
        label: t.name
    } ));

    if (TransactionStore.transactionTypes.length === 0) {
        // Modal with error message
        return (
            <Modal show={TransactionStore.showModals.createTemplate} onHide={() => TransactionStore.setShowModal('createTemplate', false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Create Transaction</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>There are no transaction types. Please create one first.</p>
                </Modal.Body>
            </Modal>
        );
    } else if (AccountStore.accounts.length <= 1) {
        // Modal with error message
        return (
            <Modal show={TransactionStore.showModals.createTemplate} onHide={() => TransactionStore.setShowModal('createTemplate', false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Create Transaction</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>There are not enough accounts. Please create two accounts first.</p>
                </Modal.Body>
            </Modal>
        );
    }



    const onSubmit = (e) => {
        e.preventDefault();
        if (fromTemplate && toTemplate) {
            TransactionStore.handleCreate(
                {
                    name: name,
                    description: description,
                    transaction_type: TransactionStore.transactionTypes[0].id,
                    from_template: fromElement,
                    to_template: toElement,
                    formula: "amount",
                },
                TransactionStore.types.TRANSACTION_TEMPLATE
            )
        } else if (fromTemplate) {
            TransactionStore.handleCreate(
                {
                    name: name,
                    description: description,
                    transaction_type: type,
                    from_template: fromElement,
                    to_account: toElement,
                    formula: formula
                },
                TransactionStore.types.TRANSACTION_TEMPLATE
            )
        } else if (toTemplate) {
            TransactionStore.handleCreate(
                {
                    name: name,
                    description: description,
                    transaction_type: type,
                    from_account: fromElement,
                    to_template: toElement,
                    formula: formula
                },
                TransactionStore.types.TRANSACTION_TEMPLATE
            )
        } else {
            TransactionStore.handleCreate(
                {
                    name: name,
                    description: description,
                    transaction_type: type,
                    from_account: fromElement,
                    to_account: toElement,
                    formula: formula
                },
                TransactionStore.types.TRANSACTION_TEMPLATE
            )
        }
        TransactionStore.setShowModal('createTemplate', false);
    };

    return (
        <Modal show={TransactionStore.showModals.createTemplate} onHide={() => TransactionStore.setShowModal('createTemplate', false)}>
            <Modal.Header closeButton>
                <Modal.Title>Create Transaction Template</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={onSubmit}>
                    <Form.Group controlId="name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="from">
                        <Form.Label>From</Form.Label>
                        <Select
                            value={
                            // find by id and value
                                concatOptions.find((a) => a.value === fromElement && a.value === fromElement) ||
                                concatOptions[0]
                            }
                            onChange={(e) => {
                                setFromElement(Number(e.value));
                                setFromTemplate(checkTemplate(e));
                            }}
                            options={concatOptions}
                            isSearchable={true}
                        />
                    </Form.Group>
                    <Form.Group controlId="to">
                        <Form.Label>To</Form.Label>
                        <Select
                            value={
                                concatOptions.find((a) => a.value === toElement && a.value === toElement) ||
                                concatOptions[1]
                            }
                            onChange={(e) => {
                                setToElement(Number(e.value));
                                setToTemplate(checkTemplate(e));
                            }}
                            options={concatOptions}
                            isSearchable={true}
                        />
                    </Form.Group>
                    <Form.Group controlId="formula">
                        <Form.Label>Formula</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter formula"
                            value={formula}
                            onChange={(e) => setFormula(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="description">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="type">
                        <Form.Label>Type</Form.Label>
                        <Select
                            value={
                                transactionTypeOptions.find((t) => t.value === type) ||
                                transactionTypeOptions[0]
                            }
                            onChange={(e) => setType(Number(e.value))}
                            options={transactionTypeOptions}
                            isSearchable={true}
                        />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        Create
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
});

export default CreateTransactionTemplateModal;
