import {axiosInstanceWarehouse} from "../../../axiosInstance";
import {observer} from "mobx-react-lite";
import ProductStore from "../../../stores/ProductStore";
import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const OzonProductDataModal = observer(({  }) => {


    useEffect(() => {
        if (ProductStore.showModals.ozonProductData) {
            ProductStore.fetchOzonProductData(ProductStore.selectedProduct.id);
        }
    }, [ProductStore.showModals.ozonProductData]);

    const fieldsConfig = [
        { field: 'article', label: 'Article', type: 'text', changeable: false },
        { field: 'sku', label: 'SKU', type: 'text', changeable: false },
        { field: 'name', label: 'Name', type: 'text', changeable: false },
        { field: 'description', label: 'Description', type: 'text', changeable: false },
        { field: 'status', label: 'Status', type: 'text', changeable: false },
        { field: 'volume', label: 'Volume', type: 'text', changeable: false },
        { field: 'weight', label: 'Weight', type: 'text', changeable: false },
        { field: 'available_on_stock', label: 'Available on Stock', type: 'text', changeable: false },
        { field: 'price', label: 'Price', type: 'text', changeable: false },
        { field: 'commission', label: 'Commission', type: 'text', changeable: false },
        { field: 'logistics_cost', label: 'Logistic Cost', type: 'text', changeable: false },
    ]



    const handleBlur = async (field, value) => {
        try {
            await ProductStore.updateOzonProductData(ProductStore.selectedProduct.id, { [field]: value });
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Modal
            show={ProductStore.showModals.ozonProductData}
            onHide={() => ProductStore.setShowModal('ozonProductData', false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Ozon Product Data</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    {fieldsConfig.map(config => (
                        <Form.Group key={config.field}>
                            <Form.Label>{config.label}</Form.Label>
                            <Form.Control
                                type={config.type}
                                value={ProductStore.ozonProductData[config.field] || ''}
                                onBlur={(e) => handleBlur(config.field, e.target.value)}
                                disabled={!config.changeable}
                            />
                        </Form.Group>
                    ))}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => ProductStore.setShowModal('ozonProductData', false)}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
});

export default OzonProductDataModal;