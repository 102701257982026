import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axiosInstance from "../../axiosInstance";

const ImportAccountsModal = ({ show, handleClose }) => {
    const [locate, setLocate] = useState('ru');

    const handleSend = () => {
        const formData = new FormData();
        formData.append('locale', locate);

        axiosInstance.post("accounts/default/", formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
            .then((response) => {
                console.log(response.data);
                handleClose();
            })
            .catch((error) => {
                console.error("An error occurred while uploading the file:", error);
            });
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Import File</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formFormat">
                        <Form.Label>Select Format</Form.Label>
                        <Form.Control as="select" value={locate} onChange={(e) => setLocate(e.target.value)}>
                            <option value="ru">RU</option>
                            <option value="en">EN</option>
                        </Form.Control>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSend}>
                    Send
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ImportAccountsModal;
