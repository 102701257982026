import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import ImportAccountsModal from './ImportAccountsModal';

const ImportAccountsButton = ({ uploadUrl }) => {
    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    return (
        <>
            <Button variant="primary" onClick={handleShow}>
                Import Default Accounts
            </Button>
            <ImportAccountsModal show={show} handleClose={handleClose} />
        </>
    );
};

export default ImportAccountsButton;
