import React from 'react';
import {Navbar, Nav, Button, Container} from 'react-bootstrap';
import {useAuth} from '../Auth/AuthContext'; // Ensure authService provides useAuth
import {LogoutButton} from '../Auth/LogoutButton';

export const Header = () => {
    const {isAuthenticated} = useAuth();

    return (
        <Navbar bg="dark" variant="dark" expand="lg">
            <Container>
                <Navbar.Brand href="/">MyApp</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <Nav.Link href="/accounts">Accounts</Nav.Link>
                        <Nav.Link href="/transactions">Transactions</Nav.Link>
                        <Nav.Link href="/financial-statements">Financial Statements</Nav.Link>
                        <Nav.Link href="/products">Products</Nav.Link>
                        <Nav.Link href="/analytics">Analytics</Nav.Link>
                    </Nav>
                    <Nav>
                        {isAuthenticated ? (
                            <LogoutButton/>
                        ) : (
                            <>
                                <Nav.Link href="/login">
                                    <Button variant="outline-success">Login</Button>
                                </Nav.Link>
                                <Nav.Link href="/register">
                                    <Button variant="outline-primary">Register</Button>
                                </Nav.Link>
                            </>
                        )}
                    </Nav>
                </Navbar.Collapse>
            </Container>

        </Navbar>
    );
};
