import React, {useEffect, useState} from 'react';
import {Modal, Button, Form} from 'react-bootstrap';
import Select from 'react-select';
import {observer} from "mobx-react-lite";
import TransactionStore from "../../../../stores/TransactionStore";

const UseTemplateModal = observer(({}) => {
    const [template, setTemplate] = useState(null); // Using null as initial value
    const [amount, setAmount] = useState(0);
    const [variables, setVariables] = useState({}); // Holds the dynamic fields values

    // log all templates

    for (const template of TransactionStore.transactionTemplates) {
        console.log(template);
    }

    const templateOptions = TransactionStore.transactionTemplates
    .filter(t => !t.is_hidden) // Filter out elements where hidden is true
    .map(t => ({
        value: t.id,
        label: t.name
    }));

    const handleTemplateChange = (selectedOption) => {
        setTemplate(selectedOption.value);

        const initialVariables = {};

        setVariables(initialVariables);
    };

    const handleVariableChange = (variable, value) => {
        setVariables(prev => ({ ...prev, [variable]: value }));
    }

    const handleCreate = () => {
        // Include variables when using the template
        console.log(template, amount, variables);
        TransactionStore.handleUseTemplate(template, {amount, variables});
        TransactionStore.setShowModal('useTemplate', false);
    }

    const selectedTemplate = template ? TransactionStore.transactionTemplates.find(t => t.id === template) : null;

    return (
        <Modal show={TransactionStore.showModals.useTemplate} onHide={() => TransactionStore.setShowModal('useTemplate', false)}>
            <Modal.Header closeButton>
                <Modal.Title>Use Template</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="template">
                        <Form.Label>Template</Form.Label>
                        <Select
                            options={templateOptions}
                            value={templateOptions.find((obj) => obj.value === template)}
                            onChange={handleTemplateChange}
                        />
                    </Form.Group>
                    {selectedTemplate && selectedTemplate.variables.map(variable => (
                        <Form.Group key={variable.id} controlId={variable.id}>
                            <Form.Label>{variable.name}</Form.Label>
                            <Form.Control
                                value={variables[variable.name] || ''}
                                onChange={(e) => handleVariableChange(variable.name, e.target.value)}
                            />
                        </Form.Group>
                    ))}
                    <Form.Group controlId="amount">
                        <Form.Label>Amount</Form.Label>
                        <Form.Control
                            type="number"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => TransactionStore.setShowModal('useTemplate', false)}>Cancel</Button>
                <Button variant="primary" onClick={handleCreate}>Use Template</Button>
            </Modal.Footer>
        </Modal>
    );
});

export default UseTemplateModal;
