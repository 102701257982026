import React from 'react';
import { Table } from 'react-bootstrap';
import AccountRow from "./AccountRow";
import { observer } from "mobx-react-lite";
import AccountStore from "../../../stores/AccountStore";  // Путь может отличаться

const AccountList = observer(() => {

    return (
        <Table bordered hover>
            <thead>
            <tr>
                <th>Number</th>
                <th>Name</th>
                <th>Balance</th>
                <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            {AccountStore.notZeroBalanceAccountsBool && AccountStore.notZeroBalanceAccounts.map(account => (
                <AccountRow
                    key={account.id}
                    account={account}
                />
            ))}
            {!AccountStore.notZeroBalanceAccountsBool && AccountStore.accounts.map(account => (
                <AccountRow
                    key={account.id}
                    account={account}
                />
            ))}
            </tbody>
        </Table>
    );
});

export default AccountList;
