import React, { useState } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import {observer} from "mobx-react-lite";
import ProductStore from "../../../stores/ProductStore";

const AccountingCalculatorModal = observer(({  }) => {
    const [selectedProduct, setSelectedProduct] = useState('');
    const [productRows, setProductRows] = useState([]);

    const handleSelectChange = (e) => {
        setSelectedProduct(e.target.value);
    };

    const handleAddProduct = () => {
        const product = ProductStore.products.find(p => p.internal_name === selectedProduct);
        if (product) {
            setProductRows([...productRows, { ...product, count: 0 }]);
        }
    };

    const handleCountChange = (index, value) => {
        const newProductRows = [...productRows];
        newProductRows[index].count = value;
        setProductRows(newProductRows);
    };

    const getTotalPrice = () => {
        return productRows.reduce((total, product) => total + (product.count * product.supplier_cost), 0);
    };

    return (
        <Modal
            show={ProductStore.showModals.accountingCalculator}
            onHide={() => ProductStore.setShowModal('accountingCalculator', false)}
        >
            <Modal.Header closeButton>
                <Modal.Title>Калькулятор стоимости продуктов</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col>
                            <Form.Control as="select" value={selectedProduct} onChange={handleSelectChange}>
                                <option value="">Выберите продукт</option>
                                {ProductStore.products.map(product => (
                                    <option key={product.internal_name} value={product.internal_name}>{product.internal_name}</option>
                                ))}
                            </Form.Control>
                        </Col>
                        <Col>
                            <Button variant="primary" onClick={handleAddProduct}>Добавить продукт</Button>
                        </Col>
                    </Row>
                    {productRows.map((product, index) => (
                        <Row key={index} className="mt-3">
                            <Col>{product.internal_name}</Col>
                            <Col>{product.supplier_cost}</Col>
                            <Col>
                                <Form.Control
                                    type="number"
                                    value={product.count}
                                    onChange={(e) => handleCountChange(index, e.target.value)}
                                />
                            </Col>
                        </Row>
                    ))}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <div>Общая стоимость: {getTotalPrice()}</div>
                <Button variant="secondary" onClick={() => ProductStore.setShowModal('accountingCalculator', false)}>
                    Закрыть
                </Button>
            </Modal.Footer>
        </Modal>
    );
});

export default AccountingCalculatorModal;
