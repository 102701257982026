import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import AccountStore from "../../../stores/AccountStore";
import {observer} from "mobx-react-lite";

const CreateAccountModal = observer(({ handleNumberChange }) => {
    const [accountNumber, setAccountNumber] = useState('');
    const [accountName, setAccountName] = useState('');
    const [accountBalance, setAccountBalance] = useState('');
    const [accountType, setAccountType] = useState('active');

    const onSubmit = () => {
        AccountStore.handleCreateAccount({ name: accountName, balance: accountBalance, number: accountNumber, type: accountType });
        AccountStore.handleCreateModalClose();
    };



    return (
        <Modal show={AccountStore.showModals.create} onHide={AccountStore.handleCreateModalClose}>
            <Modal.Header closeButton>
                <Modal.Title>Create Account</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="accountNumber">
                        <Form.Label>Number</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter account number"
                            value={accountNumber}
                            onChange={(e) => {
                                handleNumberChange(e, setAccountNumber);
                            }}
                        />
                    </Form.Group>
                    <Form.Group controlId="accountName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter account name"
                            value={accountName}
                            onChange={(e) => setAccountName(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="accountType">
                        <Form.Label>Type</Form.Label>
                        <Form.Control
                            as="select"
                            value={accountType}
                            onChange={(e) => setAccountType(e.target.value)}
                        >
                            <option value="active">Active</option>
                            <option value="passive">Passive</option>
                            <option value="active-passive">Active-Passive</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="accountBalance">
                        <Form.Label>Balance</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Enter initial balance"
                            value={accountBalance}
                            onChange={(e) => setAccountBalance(e.target.value)}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={AccountStore.handleCreateModalClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={onSubmit}>
                    Create Account
                </Button>
            </Modal.Footer>
        </Modal>
    );
});

export default CreateAccountModal;
