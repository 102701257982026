import React, { useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import {axiosInstanceWarehouse} from "../../../axiosInstance";
import {observer} from "mobx-react-lite";
import ProductStore from "../../../stores/ProductStore";
import OzonProductDataModal from "./OzonProductDataModal";
import OzonStatisticsByMonthModal from "./OzonStatisticsModal";

const ProductsRow = observer(({ product }) => {
    const [productState, setProductState] = useState(product);

    useEffect(() => {
        setProductState(product);
    }, [product]);

    const fieldsConfig = [
        { field: 'internal_name', label: 'Internal Name', type: 'text', changeable: true, style: { width: '200px' } },
        { field: 'volume', label: 'Volume', type: 'text', changeable: true },
        { field: 'weight', label: 'Weight', type: 'text', changeable: true },
        { field: 'link_to_supplier', label: 'Link to Supplier', type: 'url', changeable: true },
        { field: 'supplier_details', label: 'Supplier Details', type: 'text', changeable: true },
        { field: 'supplier_cost', label: 'Supplier Cost', type: 'text', changeable: true },
    ]

    const handleFieldChange = async (field, value) => {
        setProductState(prevState => ({ ...prevState, [field]: value }));
    };

    const handleBlur = async (field, value) => {
        try {
            await ProductStore.updateProduct(product.id, { [field]: value });
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <tr>
            {fieldsConfig.map(config => (
                <td key={config.field}>
                    <Form.Control
                        type={config.type}
                        value={productState[config.field] || ''}
                        onBlur={(e) => handleBlur(config.field, e.target.value)}
                        onChange={(e) => handleFieldChange(config.field, e.target.value)}
                        disabled={!config.changeable}
                        style={config.style}
                    />
                </td>
            ))}
            <td>
                <Button onClick={() => {
                    ProductStore.setSelectProduct(productState);
                    ProductStore.setShowModal('ozonProductData', true);
                }}>Ozon Data</Button>
            </td>
            <td>
                <Button onClick={() => {
                    ProductStore.setSelectProduct(productState);
                    ProductStore.setShowModal('ozonStatisticsByMonth', true);
                }}>Ozon Statistics</Button>
            </td>
            <td>
                <Button variant="danger" onClick={() => {
                    ProductStore.deleteProduct(product.id);
                }}>Delete</Button>
            </td>

        </tr>
    );
});

export default ProductsRow;