import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import {observer} from "mobx-react-lite";
import accountStore from "../../../stores/AccountStore";

const EditAccountModal = observer(({  }) => {
    const [name, setName] = useState(accountStore.selectedAccount.name);
    const [balance, setBalance] = useState(accountStore.selectedAccount.balance);
    const [type, setType] = useState(accountStore.selectedAccount.type);

    const handleSave = () => {
        accountStore.handleEditAccount(accountStore.selectedAccount.id, {
            number: accountStore.selectedAccount.number,
            name: name,
            balance: balance,
            type: type,
        });
        accountStore.handleEditModalClose();
    };

    return (
        <Modal show={accountStore.showModals.edit} onHide={accountStore.handleEditModalClose}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Account</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="accountName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter account name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="accountType">
                        <Form.Label>Type</Form.Label>
                        <Form.Control
                            as="select"
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                        >
                            <option value="passive">Passive</option>
                            <option value="active">Active</option>
                            <option value="active-passive">Active-Passive</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="accountBalance">
                        <Form.Label>Balance</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Enter account balance"
                            value={balance}
                            onChange={(e) => setBalance(e.target.value)}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={accountStore.handleEditModalClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSave}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
});

export default EditAccountModal;
