import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import {observer} from "mobx-react-lite";
import TransactionStore from "../../../../stores/TransactionStore";

const TransactionTypeDetailModal = observer(({  }) => {
    return (
        <Modal show={TransactionStore.showModals.detailType} onHide={() => TransactionStore.setShowModal('detailType', false)}>
            <Modal.Header closeButton>
                <Modal.Title>Transaction Type Detail</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h5>Name</h5>
                <p>{TransactionStore.selectedTransactionType.name}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => TransactionStore.setShowModal('detailType', false)}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
});
export default TransactionTypeDetailModal;

