import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import ImportModal from './ImportModal';

const ImportButton = ({ uploadUrl }) => {
    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    return (
        <>
            <Button variant="primary" onClick={handleShow} className="me-2">
                Import CSV
            </Button>
            <ImportModal show={show} handleClose={handleClose} uploadUrl={uploadUrl} />
        </>
    );
};

export default ImportButton;
