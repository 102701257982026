import React, { useState } from 'react';
import { ListGroup, Row, Col, Button, Form } from 'react-bootstrap';
import TransactionStore from "../../../../stores/TransactionStore";
import transactionStore from "../../../../stores/TransactionStore";

const TransactionTemplateItem = ({ transactionTemplate, onDetail, onDelete }) => {
    const [isHidden, setIsHidden] = useState(transactionTemplate.is_hidden);

    const handleHiddenChange = async (e) => {
        const newHiddenState = e.target.checked;
        setIsHidden(newHiddenState);

        // Replace with the actual URL and request format needed for your backend
        await TransactionStore.handleHiddenTransactionTemplate(transactionTemplate.id, newHiddenState)
    };

    return (
        <ListGroup.Item key={transactionTemplate.id} className="mb-3" style={{ backgroundColor: '#f8f9fa', border: '1px solid #dee2e6' }}>
            <Row className="mb-2">
                <Col md={4}>
                    <strong>Template:</strong> {transactionTemplate.name}
                </Col>
                <Col md={4}>
                    <strong>Formula:</strong> {transactionTemplate.formula}
                </Col>
                <Col md={4}>
                    <strong>Type:</strong> {transactionTemplate.type}
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Check
                        type="checkbox"
                        label="Hidden"
                        checked={isHidden}
                        onChange={handleHiddenChange}
                    />
                </Col>
                <Col>
                    <Button variant="primary" onClick={() => onDetail(transactionTemplate)} className="me-2">
                        Detail
                    </Button>
                    <Button variant="danger" onClick={() => onDelete(transactionTemplate, transactionStore.types.TRANSACTION_TEMPLATE)}>
                        Delete
                    </Button>
                </Col>
            </Row>
        </ListGroup.Item>
    );
};

export default TransactionTemplateItem;
