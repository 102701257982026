import {observer} from "mobx-react-lite";
import {useEffect, useState} from "react";
import ProductStore from "../../../stores/ProductStore";

const AnalyticsRow = observer(({product}) => {
    const [productState, setProductState] = useState(product);
    const [calculatedValues, setCalculatedValues] = useState({});

    useEffect(() => {
        setProductState(product);
        fieldsConfig.forEach(config => {
            if (config.calculate_func) {
                config.calculate_func(product).then(result => {
                    setCalculatedValues(prevValues => ({ ...prevValues, [config.label]: result }));
                });
            }
        });
    }, [product]);

    const calculateAverageSalesPerMonth = async (product) => {
        const analytics = product.addAnalyticsLast60Days;
        // check analytics is not null
        if (analytics === null) {
            const statistics = product.ozon_statistics;
            return statistics.reduce(
                (total, statistic) => total + statistic.orders_count_by_month, 0
            ) / statistics.length;
        } else {
            return analytics.count / 2;
        }

    }

    const calculateAverageSalesPerDay = async (product) => {
        return await calculateAverageSalesPerMonth(product) / 30;
    }

    const calculateQuantityOnStockForcalcs = async (product) => {
        const date = new Date();
        const daysBeforeEndMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate() - date.getDate();
        const averageSalesPerDay = await calculateAverageSalesPerDay(product);
        return product.count_on_warehouse - averageSalesPerDay * daysBeforeEndMonth
    }


    const calculateEnoughForDays = async (product) => {
        const averageSalesPerDay = await calculateAverageSalesPerDay(product);
        const quantityOnStock = await calculateQuantityOnStockForcalcs(product);
        if (averageSalesPerDay === 0) {
            return '∞';
        } else {
            return Math.floor(quantityOnStock / averageSalesPerDay);
        }
    }

    const calculateEnoughForMonths = async (product) => {
        const averageSalesPerMonth = await calculateAverageSalesPerMonth(product);
        const quantityOnStock = await calculateQuantityOnStockForcalcs(product);
        if (averageSalesPerMonth === 0) {
            return '∞';
        } else {
            return Math.floor(quantityOnStock / averageSalesPerMonth);
        }
    }

    const calculateMissing5Months = async (product) => {
        // avg sales per month*5-product count on warehouse
        const averageSalesPerMonth = await calculateAverageSalesPerMonth(product);
        const quantityOnStock = await calculateQuantityOnStockForcalcs(product);
        const result = Math.floor(averageSalesPerMonth * 5 - quantityOnStock);
        if (result < 0) {
            return 0;
        } else {
            return result;
        }
    }

    const calculateWhiteSupplyQuantity = async (product) => {
        // average sales per day * (white supply duration + 30) - max(product count on warehouse - average sales per day * white supply duration, 0)
        const averageSalesPerDay = await calculateAverageSalesPerDay(product);
        const whiteSupplyDuration = ProductStore.stepSettings.white_order_step_duration;
        const quantityOnStock = await calculateQuantityOnStockForcalcs(product);
        const result = Math.floor(averageSalesPerDay * (whiteSupplyDuration + 30) - Math.max(quantityOnStock - averageSalesPerDay * whiteSupplyDuration, 0));
        if (result < 0) {
            return 0;
        } else {
            return result;
        }
    }

    const calculateGraySupplyQuantity = async (product) => {
        // average sales per day * (gray supply duration + 30) - max(product count on warehouse - average sales per day * gray supply duration, 0)
        const averageSalesPerDay = await calculateAverageSalesPerDay(product);
        const graySupplyDuration = ProductStore.stepSettings.gray_order_step_duration;
        const quantityOnStock = await calculateQuantityOnStockForcalcs(product);
        const result = Math.floor(averageSalesPerDay * (graySupplyDuration + 30) - Math.max(quantityOnStock - averageSalesPerDay * graySupplyDuration, 0));
        if (result < 0) {
            return 0;
        } else {
            return result;
        }
    }


    const fieldsConfig = [
        {field: 'internal_name', label: 'Internal Name', type: 'text', changeable: false, style: {width: '200px'}},
        {field: 'count_on_warehouse', label: 'Quantity on stock', type: 'text', changeable: false},
        {field: null, label: 'Average sales per month', type: 'text', changeable: false,
            calculate_func: calculateAverageSalesPerMonth
        },
        {field: null, label: 'Average sales per day', type: 'text', changeable: false,
            calculate_func: calculateAverageSalesPerDay
        },
        {field: null, label: 'Enough for days', type: 'text', changeable: false,
            calculate_func: calculateEnoughForDays
        },
        {field: null, label: 'Enough for months', type: 'text', changeable: false,
            calculate_func: calculateEnoughForMonths
        },
        {field: null, label: 'Missing 5 months', type: 'text', changeable: false,
            calculate_func: calculateMissing5Months
        },
        {field: null, label: 'White supply quantity', type: 'text', changeable: false,
            calculate_func: calculateWhiteSupplyQuantity
        },
        {field: null, label: 'Gray supply quantity', type: 'text', changeable: false,
            calculate_func: calculateGraySupplyQuantity
        },
        {field: 'comment', label: 'Comment', type: 'text', changeable: true},
    ];

    const getDisplayValue = (config) => {
        switch (config.label) {
            case 'Internal Name':
            case 'Quantity on stock':
                return productState[config.field] || '';
            default:
                return calculatedValues[config.label] !== undefined ? calculatedValues[config.label] : 'Loading...';
        }
    };


    return (
        <tr>
            {fieldsConfig.map(config => (
                <td key={config.field ? config.field : config.label}>
                    {config.changeable ?
                        <input
                            type={config.type}
                            value={productState[config.field ? config.field : config.label]}
                            onChange={event => setProductState({...productState, [config.field]: event.target.value})}
                        />
                        :
                        <span style={config.style}>
                            {getDisplayValue(config)}
                        </span>
                    }
                </td>
            ))}
        </tr>
    );
});

export default AnalyticsRow;

