import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_ACCOUNTING_BACKEND_URL,
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json',
    },
});

const axiosInstanceWarehouse = axios.create({
    baseURL: process.env.REACT_APP_ACCOUNTING_BACKEND_URL,
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json',
    }
});

axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('access');
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

axiosInstanceWarehouse.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('access');
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const refreshToken = localStorage.getItem('refresh');
            try {
                const { data } = await axiosInstance.post('/auth/token/refresh/', {refresh: refreshToken});
                localStorage.setItem('access', data.access);
                axiosInstance.defaults.headers['Authorization'] = "Bearer " + data.access;
                originalRequest.headers['Authorization'] = "Bearer " + data.access;
                return axiosInstance(originalRequest);
            } catch (_error) {
                if (_error.response && _error.response.status === 401) {
                    localStorage.removeItem('access');
                    localStorage.removeItem('refresh');
                    window.location.href = '/login/';
                }
                return Promise.reject(_error);
            }
        }
        return Promise.reject(error);
    }
);

axiosInstanceWarehouse.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const refreshToken = localStorage.getItem('refresh');
            try {
                const { data } = await axiosInstance.post('/auth/token/refresh/', {refresh: refreshToken});
                localStorage.setItem('access', data.access);
                axiosInstance.defaults.headers['Authorization'] = "Bearer " + data.access;
                originalRequest.headers['Authorization'] = "Bearer " + data.access;
                return axiosInstance(originalRequest);
            } catch (_error) {
                if (_error.response && _error.response.status === 401) {
                    localStorage.removeItem('access');
                    localStorage.removeItem('refresh');
                    window.location.href = '/login/';
                }
                return Promise.reject(_error);
            }
        }
        return Promise.reject(error);
    }
);
export default axiosInstance;
export { axiosInstanceWarehouse };
