import React from 'react';
import {ListGroup, Button, Col, Row} from 'react-bootstrap';
import {observer} from "mobx-react-lite";
import TransactionStore from "../../../../stores/TransactionStore";

const TransactionTypeList = observer(({  }) => {
    return (
        <ListGroup>
            {TransactionStore.transactionTypes.map((type) => (
                <ListGroup.Item key={type.id} className="mb-3" style={{backgroundColor: type.color, border: '1px solid #dee2e6'}}>
                    <Row className="align-items-center">
                        <Col md={6}>
                            <strong>{type.name}</strong>
                        </Col>
                        <Col md={6} className="text-end">
                            <Button variant="info" onClick={() => TransactionStore.onDetail(type, TransactionStore.types.TRANSACTION_TYPE)} className="me-2">
                                Details
                            </Button>
                            <Button variant="warning" onClick={() => TransactionStore.onEdit(type, TransactionStore.types.TRANSACTION_TYPE)} className="me-2">
                                Edit
                            </Button>
                            <Button variant="danger" onClick={() => TransactionStore.handleDelete(type, TransactionStore.types.TRANSACTION_TYPE)}>
                                Delete
                            </Button>
                            <Button variant="success" onClick={() => TransactionStore.onChangeColor(type)} className="ms-2">
                                Change Color
                            </Button>
                        </Col>
                    </Row>
                </ListGroup.Item>
            ))}
        </ListGroup>
    );
});
export default TransactionTypeList;

