import {observer} from "mobx-react-lite";
import {Container} from "react-bootstrap";
import AnalyticsList from "./AlalyticsList";
import {useEffect} from "react";
import ProductStore from "../../../stores/ProductStore";

const AnalyticsPage = observer(() => {
    useEffect(
        () => {
            ProductStore.fetchStepSettings();
            ProductStore.fetchAnalyticsLast60Days();
            ProductStore.fetchProductsWithAnalytics();
        }, []
    );
    return (
        <Container>
            <h1>Analytics</h1>

            {ProductStore.loading.fetchAnalyticsLast60Days ? <AnalyticsList/> : <h2>Loading...</h2>}
        </Container>
    );
});

export default AnalyticsPage;