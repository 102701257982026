import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ProfitLossStatement from './ProfitLossStatement';
import BalanceSheet from './BalanceSheet';
import CashFlowStatement from './CashFlowStatement';
import GeneralReport from "./GeneralReport";

const FinancialStatementsPage = () => {
    return (
        <Container>
            <h1>Financial Statements</h1>

            <Row className="mb-3">
                <Col>
                    <ProfitLossStatement />
                </Col>
            </Row>

            <Row className="mb-3">
                <Col>
                    <BalanceSheet />
                </Col>
            </Row>

            <Row className="mb-3">
                <Col>
                    <CashFlowStatement />
                </Col>
            </Row>

            <Row className="mb-3">
                <Col>
                    <GeneralReport />
                </Col>
            </Row>
        </Container>
    );
};

export default FinancialStatementsPage;
