import React from 'react';
import { Button } from 'react-bootstrap';
import SubAccountRow from './SubAccountRow';
import { observer } from "mobx-react-lite";
import AccountStore from "../../../stores/AccountStore";  // Путь может отличаться

const AccountRow = observer(({ account }) => {

    const expanded = AccountStore.expandedAccounts.includes(account.id);

    return (
        <>
            <tr key={account.id} onClick={() => AccountStore.toggleAccountExpansion(account.id)}>
                <td>{account.number}</td>
                <td style={{cursor: 'pointer'}}>
                    {account.name}
                </td>
                <td>{account.balance}</td>
                <td>{account.type}</td>
                <td>
                    <Button variant="info" onClick={() => AccountStore.handleSelectAccount(account)}>
                        Details
                    </Button>
                    {' '}
                    <Button variant="warning" onClick={() => AccountStore.handleEditAccountInitiation(account)}>
                        Edit
                    </Button>
                    {' '}
                    <Button variant="danger" onClick={() => AccountStore.handleDeleteAccount(account.id)}>
                        Delete
                    </Button>
                    {' '}
                    <Button variant="success" onClick={() => AccountStore.handleCreateSubAccountInitiation(account)}>
                        Create
                    </Button>
                </td>
            </tr>
            {expanded && account.subAccounts && account.subAccounts.map(subAccount => (
                <SubAccountRow
                    key={subAccount.id}
                    subAccount={subAccount}
                    parentAccount={account}
                />
            ))}
        </>
    );
});

export default AccountRow;
