import React, { useState, useEffect } from 'react';
import { Table, Button } from 'react-bootstrap';
import ProductStore from "../../../stores/ProductStore";
import ProductsRow from "./ProductsRow";
import {observer} from "mobx-react-lite";
import OzonProductDataModal from "./OzonProductDataModal";
import OzonStatisticsByMonthModal from "./OzonStatisticsModal";

const ProductsList = observer(({ }) => {
    useEffect(() => {
        ProductStore.fetchProducts();
    }, []);
    return (
        <div>
            <h2>Products</h2>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Internal Name</th>
                        <th>Volume</th>
                        <th>Weight</th>
                        <th>Link to Supplier</th>
                        <th>Supplier Details</th>
                        <th>Supplier Cost</th>
                        <th>Ozon Data</th>
                        <th>Ozon Statistics</th>
                    </tr>
                </thead>
                <tbody>
                    {ProductStore.products.map(product => (
                        <ProductsRow key={product.id} product={product} />
                    ))}
                </tbody>
            </Table>
            {ProductStore.showModals.ozonProductData && <OzonProductDataModal />}
            {ProductStore.showModals.ozonStatisticsByMonth && <OzonStatisticsByMonthModal />}
        </div>
    );
});

export default ProductsList;