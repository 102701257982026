import {makeAutoObservable, runInAction} from "mobx";
import axiosInstance from "../axiosInstance";

class AccountStore {
    accounts = [];
    error = null;
    loading = false;
    selectedAccount = null;
    expandedAccounts = [];
    parentAccount = null;
    showModals = {
        detail: false,
        create: false,
        edit: false
    }
    notZeroBalanceAccountsBool = true;
    notZeroBalanceAccounts = [];

    handleError(error) {
        runInAction(() => {
            this.error = error.response?.data?.message || 'An unexpected error occurred';
        });
    }

    constructor() {
        makeAutoObservable(this);
    }

    setShowModal = (modal, show) => {
        runInAction(() => {
            this.showModals[modal] = show;
        });
    }

    editSubAccount = (account, parentAccount) => {
        runInAction(() => {
            this.selectedAccount = account;
            this.showModals.edit = true;
            this.parentAccount = parentAccount;
        });
    }

    fetchAllAccounts = async () => {
        try {
            runInAction(() => {
                this.loading = true;
            });
            const response = await axiosInstance.get('/accounts/?all=True');
            runInAction(() => {
                this.accounts = response.data;
            });
        } catch (err) {
            this.handleError(err);
        }
    }



    fetchSubAccountsByAccount = async (accountId) => {
        try {
            const response = await axiosInstance.get(`accounts/${accountId}/sub_accounts/`);
            runInAction(() => {
                this.accounts = this.accounts.map(acc => {
                    if (acc.id === accountId) {
                        return {...acc, subAccounts: response.data};
                    }
                    return acc;
                });
            });
        } catch (err) {
            this.handleError(err);
        }
    }

    fetchAccountsWithSubAccounts = async () => {
        try {
            runInAction(() => {
                this.loading = true;
            });
            const response = await axiosInstance.get('accounts/');
            runInAction(() => {
                this.accounts = response.data;
            });
            await Promise.all(
                this.accounts.map(async (account) => {
                    await this.fetchSubAccountsByAccount(account.id);
                })
            );
            runInAction(() => {
                this.notZeroBalanceAccounts = this.accounts.filter(acc => acc.balance !== "0.00" || acc.subAccounts.length !== 0);
            });
        } catch (err) {
            this.handleError(err);
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    handleCreateAccount = async (account) => {
        try {
            runInAction(() => {
                this.loading = true;
            });
            let newAccount;
            if (this.parentAccount) {
                account.number = this.parentAccount.number + '.' + account.number;
                const response = await axiosInstance.post(`accounts/${this.parentAccount.id}/create_sub_account/`, account);
                newAccount = response.data;
            } else {
                const response = await axiosInstance.post('accounts/create/', account);
                newAccount = response.data;
            }
            runInAction(() => {
                this.updateAccountsState(newAccount);
            });
            runInAction(() => {
                this.selectedAccount = null;
                this.parentAccount = null;
            });
        } catch (err) {
            this.handleError(err);
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    updateAccountsState = (newAccount) => {
        runInAction(() => {
            if (this.parentAccount) {
                this.accounts = this.accounts.map(acc => {
                    if (acc.id === this.parentAccount.id) {
                        return {...acc, subAccounts: [...acc.subAccounts, newAccount]}
                    }
                    return acc;
                });
            } else {
                this.accounts = [...this.accounts, newAccount];
            }
        });
    }

    handleDeleteAccount = async (accountId, parentAccount) => {
        try {
            runInAction(() => {
                this.loading = true;
            });
            await axiosInstance.delete(`accounts/${accountId}/`);
            runInAction(() => {
                if (parentAccount) {
                    this.accounts = this.accounts.map(acc => {
                        if (acc.id === parentAccount.id) {
                            return {...acc, subAccounts: acc.subAccounts.filter(subAcc => subAcc.id !== accountId)}
                        }
                        return acc;
                    });
                } else {
                    this.accounts = this.accounts.filter(account => account.id !== accountId);
                }
            });
        } catch (err) {
            this.handleError(err);
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    handleEditAccount = async (accountId, updatedAccount) => {
        try {
            runInAction(() => {
                this.loading = true;
            });
            const response = await axiosInstance.put(`accounts/${accountId}/`, updatedAccount);
            runInAction(() => {
                if (this.parentAccount) {
                    this.accounts = this.accounts.map(acc => {
                        if (acc.id === this.parentAccount.id) {
                            return {...acc, subAccounts: acc.subAccounts.map(subAcc => {
                                    if (subAcc.id === accountId) {
                                        return response.data;
                                    }
                                    return subAcc;
                                })}
                        }
                        return acc;
                    });
                } else {
                    this.accounts = this.accounts.map(account => {
                        if (account.id === accountId) {
                            return {
                                ...response.data,
                                subAccounts: account.subAccounts
                            };
                        }
                        return account;
                    });
                }
            });
            runInAction(() => {
                this.parentAccount = null;
                this.selectedAccount = null;
            });
        } catch (err) {
            this.handleError(err);
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    toggleAccountExpansion = (accountId) => {
        runInAction(() => {
            if (this.expandedAccounts.includes(accountId)) {
                this.expandedAccounts = this.expandedAccounts.filter(id => id !== accountId);
            } else {
                this.expandedAccounts = [...this.expandedAccounts, accountId];
            }
        });
    }

    handleSelectAccount = (account) => {
        runInAction(() => {
            this.selectedAccount = account;
            this.showModals.detail = true;
        });
    }

    handleEditAccountInitiation = (account) => {
        runInAction(() => {
            this.selectedAccount = account;
            this.showModals.edit = true;
        });
    }

    handleCreateSubAccountInitiation = (account) => {
        runInAction(() => {
            this.setShowModal('create', true)
            this.parentAccount = account;
        });
    }

    handleEditModalClose = () => {
        runInAction(() => {
            this.setShowModal('edit', false);
        });
    }

    handleCreateModalClose = () => {
        runInAction(() => {
            this.setShowModal('create', false);
        });
    }
    setNotZeroBalanceAccounts = (value) => {
        runInAction(() => {
            this.notZeroBalanceAccountsBool = value;
        });
    }


}

export default new AccountStore();