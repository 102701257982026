import React, {useState} from 'react';
import {Button, Form} from 'react-bootstrap';
import axiosInstance from "../../../axiosInstance";

const BalanceSheet = () => {
    const [balanceSheetParams, setBalanceSheetParams] = useState({
        not_zero_balance: 'no',
    });

    const handleDownloadBalanceSheet = async () => {
        try {
            const response = await axiosInstance.get('/financial_statements/balance_sheet/', {
                responseType: 'blob',
                params: balanceSheetParams,
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'BalanceSheet.xlsx'); // or '.xls'
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error("An error occurred while downloading the file: ", error);
            // Handle error accordingly
        }
    };

    return (
        <>
            <h2>Balance Sheet</h2>
            <Form>
                <Form.Check
                    type="switch"
                    id="not_zero_balance_switch"
                    label="Not Zero Balance"
                    onChange={() => setBalanceSheetParams({
                        ...balanceSheetParams,
                        not_zero_balance: balanceSheetParams.not_zero_balance === 'yes' ? 'no' : 'yes'
                    })}
                />
            </Form>
            <Button onClick={handleDownloadBalanceSheet}>
                Download Balance Sheet
            </Button>
        </>
    );
};

export default BalanceSheet;

