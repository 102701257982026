import React, {useState} from 'react';
import {Modal, Button, Form, Row, Col} from 'react-bootstrap';
import {observer} from "mobx-react-lite";
import ProductStore from "../../../stores/ProductStore";

const AccountingCalculatorStatisticsModal = observer(({}) => {
    const [productRows, setProductRows] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const fetchStatistics = async (start_date, end_date) => {
        const rows = [];
        await ProductStore.fetchPeriodStatistics(ProductStore.wsActions.fetchPeriodStatistics, start_date, end_date);
        // wait while statistics will be fetched
        while (ProductStore.periodStatistics.length === 0) {
            await new Promise(r => setTimeout(r, 500));
        }
        ProductStore.periodStatistics.forEach((statistic) => {
            // wait while products will be fetched
            const product = ProductStore.products.find(p => p.internal_name === statistic.internal_name);
            if (product) {
                if (statistic.count === 0) return;
                rows.push({
                    ...product,
                    count: statistic.count,
                    rate: statistic.rate
                })
            }
        })
        setProductRows(rows);
    };

    const handleDeleteProduct = (index) => {
        const updatedRows = [...productRows];
        updatedRows.splice(index, 1);
        setProductRows(updatedRows);
    };

    const getTotalPrice = () => {
        return productRows.reduce((total, product) => total + (product.count * product.supplier_cost * product.rate), 0);
    };

    return (
        <Modal
            show={ProductStore.showModals.accountingCalculatorStatistics}
            onHide={() => ProductStore.setShowModal('accountingCalculatorStatistics', false)}
        >
            <Modal.Header closeButton>
                <Modal.Title>Калькулятор стоимости продуктов за
                    период</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col>
                            <Form.Control
                                type="date"
                                placeholder="Начало периода"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                        </Col>
                        <Col>
                            <Form.Control
                                type="date"
                                placeholder="Конец периода"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                        </Col>
                        <Col>
                            <Button variant="primary"
                                    onClick={() => fetchStatistics(startDate, endDate)}>Получить
                                статистику</Button>
                        </Col>
                    </Row>
                    {productRows.map((product, index) => (
                        <Row key={index} className="mt-3">
                            <Col>{product.internal_name}</Col>
                            <Col>{product.supplier_cost * product.rate}</Col>
                            <Col>{product.count}</Col>
                            <Col>
                                <Button
                                    variant="danger"
                                    onClick={() => handleDeleteProduct(index)}
                                >
                                    Удалить
                                </Button>
                            </Col>
                        </Row>
                    ))}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <div>Общая стоимость: {getTotalPrice()}</div>
                <Button variant="secondary"
                        onClick={() => ProductStore.setShowModal('accountingCalculatorStatistics', false)}>
                    Закрыть
                </Button>
            </Modal.Footer>
        </Modal>
    );
});

export default AccountingCalculatorStatisticsModal;
