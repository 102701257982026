import {observer} from "mobx-react-lite";
import ProductStore from "../../../stores/ProductStore";
import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const OzonStatisticsByMonthModal = observer(({  }) => {
    const [ozonStatistics, setOzonStatistics] = useState([]);

    useEffect(() => {
        setOzonStatistics(ProductStore.selectedProduct.ozon_statistics);
    }, [ProductStore.selectedProduct]);

    const fieldsConfig = [
        { field: 'month', label: 'Month', type: 'text', changeable: false },
        { field: 'year', label: 'Year', type: 'text', changeable: false },
        { field: 'orders_count_by_month', label: 'Orders Count', type: 'text', changeable: false },
        { field: 'unique_visitors', label: 'Unique Visitors', type: 'text', changeable: false },
        { field: 'returns_count', label: 'Returns Count', type: 'text', changeable: false },
        { field: 'cancellations_count', label: 'Cancellations Count', type: 'text', changeable: false }
    ];

    return (
        <Modal
            show={ProductStore.showModals.ozonStatisticsByMonth}
            onHide={() => ProductStore.setShowModal('ozonStatisticsByMonth', false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Ozon Statistics By Month</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    {ozonStatistics.map((ozonStatistic, index) => (
                        <div key={index}>
                            <h5>{ozonStatistic.month} {ozonStatistic.year}</h5>
                            {fieldsConfig.map(config => (
                                <Form.Group key={config.field}>
                                    <Form.Label>{config.label}</Form.Label>
                                    <Form.Control
                                        type={config.type}
                                        value={ozonStatistic[config.field] || ''}
                                        disabled={!config.changeable}
                                    />
                                </Form.Group>
                            ))}
                        </div>
                    ))}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => ProductStore.setShowModal('ozonStatisticsByMonth', false)}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
});

export default OzonStatisticsByMonthModal;
