import React from 'react';
import { ListGroup } from 'react-bootstrap';
import TransactionTemplateItem from './TransactionTemplateItem';
import {observer} from "mobx-react-lite";
import TransactionStore from "../../../../stores/TransactionStore"; // adjust the path as necessary

const TransactionTemplateList = observer(() => {
    return (
        <ListGroup>
            {TransactionStore.transactionTemplates.map((transactionTemplate) => (
                <TransactionTemplateItem
                    key={transactionTemplate.id}
                    transactionTemplate={transactionTemplate}
                    onDetail={TransactionStore.onDetail}
                    onDelete={TransactionStore.handleDelete}
                />
            ))}
        </ListGroup>
    );
});

export default TransactionTemplateList;
