import React, { useContext, createContext, useState } from "react";
import axiosInstance from "../../axiosInstance";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const login = async (credentials) => {
        try {
            const { data } = await axiosInstance.post('/auth/login/', credentials);
            localStorage.setItem("access", data.access_token);
            localStorage.setItem("refresh", data.refresh_token);
            setIsAuthenticated(true);
        } catch (err) {
            console.error(err);
            // Handle error accordingly
        }
    };

    const logout = () => {
        localStorage.removeItem("access");
        localStorage.removeItem("refresh");
        setIsAuthenticated(false);
    };

    const register = async (credentials) => {
        try {
            const { data } = await axiosInstance.post('/auth/register/', credentials);
            localStorage.setItem("access", data.access_token);
            localStorage.setItem("refresh", data.refresh_token);
            setIsAuthenticated(true);
        } catch (err) {
            console.error(err);
            // Handle error accordingly
        }
    }

    const isAuthenticatedCheck = async () => {
        try {
            const { data } = await axiosInstance.get('/auth/user/');
            setIsAuthenticated(true);
            return data;
        } catch (err) {
            console.error(err);
            setIsAuthenticated(false);
            return null;
            // Handle error accordingly
        }
    }

    return (
        <AuthContext.Provider value={{ login, logout, register, isAuthenticatedCheck }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
