import React, {useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { Header } from './components/Header/Header';
import { LoginForm } from './components/Auth/LoginForm';
import { RegisterForm } from './components/Auth/RegisterForm';
import { AuthProvider, useAuth } from './components/Auth/AuthContext';
import AccountsPage from "./components/Accounting/Accounts/AccountsPage";
import TransactionsPage from "./components/Accounting/Transactions/TransactionsPage";
import FinancialStatementsPage from "./components/Accounting/FinancialStatements/FinancialStatementsPage";
import ProductsPage from "./components/Warehouse/Products/ProductsPage";
import AnalyticsPage from "./components/Warehouse/Analytics/AnalyticsPage";


const ProtectedRoute = ({ children }) => {
    const { isAuthenticatedCheck } = useAuth()
    let isAuthenticated = false;
    isAuthenticated = isAuthenticatedCheck().then((data) => {
        if (data) {
            isAuthenticated = data;
        }
    });
    return isAuthenticated ? children : <Navigate to="/login" />;
};

export const App = () => {
    useEffect(() => {
        document.title = "Warehouse";
    }, []);
    return (
        <AuthProvider>
            <Router>
                <Header />
                <Container>
                    <Routes>
                        <Route path="/login" element={<LoginForm />} />
                        <Route path="/register" element={<RegisterForm />} />
                        <Route path="/accounts" element={<ProtectedRoute><AccountsPage /></ProtectedRoute>} />
                        <Route path="/transactions" element={<ProtectedRoute><TransactionsPage /></ProtectedRoute>} />
                        <Route path="/financial-statements" element={<ProtectedRoute><FinancialStatementsPage /></ProtectedRoute>} />
                        <Route path="/products" element={<ProtectedRoute><ProductsPage /></ProtectedRoute>} />
                        <Route path="/analytics" element={<ProtectedRoute><AnalyticsPage /></ProtectedRoute>} />
                        <Route path="*" element={<Navigate to="/accounts" replace />} />
                    </Routes>
                </Container>
            </Router>
        </AuthProvider>
    );
};
