import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import {observer} from "mobx-react-lite";
import TransactionStore from "../../../../stores/TransactionStore";

const TransactionTypeChangeColorModal = observer(({  }) => {
    const [color, setColor] = useState('#f8f9fa');

    const onSubmit = (e) => {
        e.preventDefault();
        TransactionStore.handleChangeColor(color);
        TransactionStore.setShowModal('changeColor', false);
    }

    return (
        <Modal show={TransactionStore.showModals.changeColor} onHide={() => TransactionStore.setShowModal('changeColor', false)}>
            <Modal.Header closeButton>
                <Modal.Title>Change Transaction Type Color</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={onSubmit}>
                    <Form.Group controlId="color">
                        <Form.Label>Color</Form.Label>
                        <Form.Control type="color" value={color} onChange={(e) => setColor(e.target.value)} />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        Update
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
});

export default TransactionTypeChangeColorModal;