import axiosInstance from "../../../axiosInstance";
import {Button} from "react-bootstrap";


const GeneralReport = () => {
    const handleDownloadGeneralReport = async () => {
        try {
            const response = await axiosInstance.get('/financial_statements/general_report/', {
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            // Value in setAttribute = GeneralReport{{ today_date }}.xlsx
            const date = new Date();
            link.setAttribute('download', 'GeneralReport' + date.toString() + '.xlsx'); // or '.xls'
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error("An error occurred while downloading the file: ", error);
            // Handle error accordingly
        }
    };

    return (
        <>
            <h2>General Report</h2>
            <Button onClick={handleDownloadGeneralReport}>
                Download General Report
            </Button>
        </>
    );
}

export default GeneralReport;