import React, { useState } from 'react';
import { Button, Form, FormControl, FormGroup, FormLabel } from 'react-bootstrap';
import axiosInstance from "../../../axiosInstance";

const CashFlowStatement = () => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const handleDownloadCashFlowStatement = async () => {
        try {
            const response = await axiosInstance.get('/financial_statements/cash_flow_statement/', {
                responseType: 'blob',
                params: {
                    start_date: startDate,
                    end_date: endDate,
                },
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `CashFlowStatement_${startDate}_to_${endDate}.xlsx`);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error("An error occurred while downloading the file: ", error);
        }
    };

    return (
        <>
            <h2>Cash Flow Statement</h2>
            <Form inline>
                <FormGroup controlId="startDate">
                    <FormLabel>Start Date</FormLabel>
                    <FormControl
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                    />
                </FormGroup>
                {' '}
                <FormGroup controlId="endDate">
                    <FormLabel>End Date</FormLabel>
                    <FormControl
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                    />
                </FormGroup>
                {' '}
                <Button onClick={handleDownloadCashFlowStatement}>
                    Download Cash Flow Statement
                </Button>
            </Form>
        </>
    );
};

export default CashFlowStatement;
