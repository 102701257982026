import React, {useState} from 'react';
import {Modal, Button, Form} from 'react-bootstrap';
import {observer} from "mobx-react-lite";
import TransactionStore from "../../../stores/TransactionStore";

const TransactionSetDateModal = observer(({}) => {
    const [date, setDate] = useState('');

    const onSubmit = (e) => {
        e.preventDefault();
        TransactionStore.setDate(date);
    }

    return (
        <Modal show={TransactionStore.showModals.setDate} onHide={() => TransactionStore.setShowModal('setDate', false)}>
            <Modal.Header closeButton>
                <Modal.Title>Set Date</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={onSubmit}>
                    <Form.Group controlId="date">
                        <Form.Label>Date</Form.Label>
                        <Form.Control
                            type="date"
                            placeholder="Enter date"
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                        />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        Submit
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
});

export default TransactionSetDateModal;