import React from 'react';
import { Button } from 'react-bootstrap';
import {useAuth} from "./AuthContext";

export const LogoutButton = () => {
    const { logout } = useAuth();

    return (
        <Button variant="secondary" onClick={logout}>
            Logout
        </Button>
    );
};
