import React, {useEffect} from 'react';
import AccountDetailModal from './AccountDetailModal';
import CreateAccountModal from './CreateAccountModal';
import AccountList from './AccountList';
import {Alert, Button} from 'react-bootstrap';
import EditAccountModal from "./EditAccountModal";
import ImportButton from "../../Import/ImportButton";
import ImportAccountsButton from "../../Import/ImportAccountsButton";
import {observer} from "mobx-react-lite";
import AccountStore from "../../../stores/AccountStore";
import {ToggleButton} from "react-bootstrap";

function MyToggleSwitch({checked, setChecked, label}) {


    return (
        <div>
            <ToggleButton
                id="toggle-check"
                type="checkbox"
                variant="secondary"
                checked={checked}
                value="1"
                onChange={(e) => setChecked(e.currentTarget.checked)}
            >
                {label}
            </ToggleButton>
        </div>
    );
}

const AccountsPage = observer(() => {

    const handleCreateModalOpen = () => AccountStore.setShowModal('create', true);
    const handleDetailModalClose = () => AccountStore.setShowModal('detail', false);

    // Получение аккаунтов при монтировании компонента
    useEffect(() => {
        AccountStore.fetchAccountsWithSubAccounts();
    }, []);

    const handleNumberChange = (e, setter) => {
        const value = e.target.value;

        if (/^[0-9]*$/.test(value)) {
            setter(value);
        }
    }

    return (
        <div>
            <h1>Accounts</h1>
            {AccountStore.error && <Alert variant="danger">{AccountStore.error}</Alert>}
            <div className="mb-3">
                <Button variant="primary" className="me-2" onClick={handleCreateModalOpen}>
                    Create Account
                </Button>
                <ImportButton uploadUrl="/accounts/import/" />
                <ImportAccountsButton />
                <MyToggleSwitch
                    checked={AccountStore.notZeroBalanceAccountsBool}
                    setChecked={AccountStore.setNotZeroBalanceAccounts}
                    label="Not Zero Balance Accounts"
                />

            </div>
            <AccountList/>
            {AccountStore.selectedAccount && (
                <AccountDetailModal
                    show={AccountStore.showModals.detail}
                    handleClose={handleDetailModalClose}
                    account={AccountStore.selectedAccount}
                />
            )}
            {AccountStore.selectedAccount && (
                <EditAccountModal/>
            )}
            <CreateAccountModal
                handleNumberChange={handleNumberChange}
            />
        </div>
    );
}
);


export default AccountsPage;
