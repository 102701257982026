import {makeAutoObservable, runInAction} from "mobx";
import axiosInstance from "../axiosInstance";
import AccountStore from "./AccountStore";

class TransactionStore {
    transactions = [];
    transactionTemplates = [];
    transactionTypes = [];
    error = null;
    loading = false;
    selectedTransaction = null;
    selectedTransactionType = null;
    selectedTransactionTemplate = null;
    showModals = {
        detail: false,
        create: false,
        createType: false,
        editType: false,
        detailType: false,
        createTemplate: false,
        detailTemplate: false,
        useTemplate: false,
        setDate: false,
        changeColor: false,
    }
    types = {
        TRANSACTION: 'transaction',
        TRANSACTION_TYPE: 'transactionType',
        TRANSACTION_TEMPLATE: 'transactionTemplate'
    }

    onEdit = (elem, typeOfElem ) => {
        runInAction(() => {
            if (typeOfElem === this.types.TRANSACTION) {
                this.selectedTransaction = elem;
                this.showModals.edit = true;
            } else if (typeOfElem === this.types.TRANSACTION_TYPE) {
                this.selectedTransactionType = elem;
                this.showModals.editType = true;
            } else if (typeOfElem === this.types.TRANSACTION_TEMPLATE) {
                this.selectedTransactionTemplate = elem;
                this.showModals.editTemplate = true;
            }
        });
    }

    onDetail = (elem, typeOfElem) => {
        runInAction(() => {
            if (typeOfElem === this.types.TRANSACTION) {
                this.selectedTransaction = elem;
                this.showModals.detail = true;
            }
            else if (typeOfElem === this.types.TRANSACTION_TYPE) {
                this.selectedTransactionType = elem;
                this.showModals.detailType = true;
            } else if (typeOfElem === this.types.TRANSACTION_TEMPLATE) {
                this.selectedTransactionTemplate = elem;
                this.showModals.detailTemplate = true;
            }
        });
    }

    handleDelete = async (elem, typeOfElem) => {
        console.log(elem, typeOfElem)
        this.loading = true;
        try {
            if (typeOfElem === this.types.TRANSACTION) {
                await axiosInstance.delete(`/transactions/${elem.id}/`);
                runInAction(() => {
                    this.transactions = this.transactions.filter(transaction => transaction.id !== elem.id);
                });
            } else if (typeOfElem === this.types.TRANSACTION_TYPE) {
                await axiosInstance.delete(`/transaction_types/${elem.id}/`);
                runInAction(() => {
                    this.transactionTypes = this.transactionTypes.filter(transactionType => transactionType.id !== elem.id);
                });
            } else if (typeOfElem === this.types.TRANSACTION_TEMPLATE) {
                await axiosInstance.delete(`/transaction_templates/${elem.id}/`);
                runInAction(() => {
                    this.transactionTemplates = this.transactionTemplates.filter(transactionTemplate => transactionTemplate.id !== elem.id);
                });
            }
        } catch (error) {
            this.handleError(error);
        } finally {
            this.loading = false;
        }
    }

    handleCreate = async (elem, typeOfElem) => {
        this.loading = true;
        try {
            if (typeOfElem === this.types.TRANSACTION) {
                await axiosInstance.post('/transactions/create/', elem);
                runInAction(() => {
                    this.transactions.push(elem);
                });
            } else if (typeOfElem === this.types.TRANSACTION_TYPE) {
                await axiosInstance.post('/transaction_types/create/', elem);
                runInAction(() => {
                    this.transactionTypes.push(elem);
                });
            } else if (typeOfElem === this.types.TRANSACTION_TEMPLATE) {
                await axiosInstance.post('/transaction_templates/create/', elem);
                runInAction(() => {
                    this.transactionTemplates.push(elem);
                });
            }
        } catch (error) {
            this.handleError(error);
        } finally {
            this.loading = false;
        }
    }

    handleEdit = async (elem, typeOfElem) => {
        this.loading = true;
        try {
            if (typeOfElem === this.types.TRANSACTION_TYPE) {
                await axiosInstance.put(`/transaction_types/${elem.id}/`, elem);
                runInAction(() => {
                    this.transactionTypes = this.transactionTypes.map(transactionType => transactionType.id === elem.id ? elem : transactionType);
                });
            } else if (typeOfElem === this.types.TRANSACTION_TEMPLATE) {
                await axiosInstance.put(`/transaction_templates/${elem.id}/`, elem);
                runInAction(() => {
                    this.transactionTemplates = this.transactionTemplates.map(transactionTemplate => transactionTemplate.id === elem.id ? elem : transactionTemplate);
                });
            }
        } catch (error) {
            this.handleError(error);
        } finally {
            this.loading = false;
        }
    }

    setShowModal = (modal, show) => {
        runInAction(() => {
            this.showModals[modal] = show;
        });
    }

    handleError(error) {
        runInAction(() => {
            this.error = error.response?.data?.message || 'An unexpected error occurred';
        });
    }

    setLoading(isLoading) {
        runInAction(() => {
            this.loading = isLoading;
        });
    }

    fetchTransactions = async () => {
        this.setLoading(true);
        try {
            // Здесь нужен ваш API запрос для получения списка транзакций.
            const response = await axiosInstance.get('/transactions/');
            runInAction(() => {
                this.transactions = response.data;
            });
        } catch (error) {
            this.handleError(error);
        } finally {
            this.setLoading(false);
        }
    };

    fetchTransactionTypes = async () => {
        this.setLoading(true);
        try {
            // Здесь нужен ваш API запрос для получения списка типов транзакций.
            const response = await axiosInstance.get('/transaction_types/');
            runInAction(() => {
                this.transactionTypes = response.data;
            });
        } catch (error) {
            this.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    fetchTransactionTemplates = async () => {
        this.setLoading(true);
        try {
            // Здесь нужен ваш API запрос для получения списка шаблонов транзакций.
            const response = await axiosInstance.get('/transaction_templates/');
            runInAction(() => {
                this.transactionTemplates = response.data;
            });
        } catch (error) {
            this.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    initializePageData = async () => {
        await this.fetchTransactions();
        await this.fetchTransactionTypes();
        await this.fetchTransactionTemplates();
        await AccountStore.fetchAllAccounts();
    }


    constructor() {
        makeAutoObservable(this);
    }

    handleDeleteTransactionType = async (id) => {
        this.setLoading(true);
        try {
            await axiosInstance.delete(`/transactionTypes/${id}/`);
            runInAction(() => {
                this.transactionTypes = this.transactionTypes.filter((type) => type.id !== id);
            });
        } catch (error) {
            this.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    handleDeleteTransactionTemplate = async (id) => {
        this.setLoading(true);
        try {
            await axiosInstance.delete(`/transactionTemplates/${id}/`);
            runInAction(() => {
                this.transactionTemplates = this.transactionTemplates.filter((template) => template.id !== id);
            });
        } catch (error) {
            this.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    handleUseTemplate = async (templateId, transactionData) => {
        this.setLoading(true);
        try {
            await axiosInstance.post(`/transaction_templates/use/${templateId}/`, transactionData);
            // refetch transactions
            await this.fetchTransactions();
        } catch (error) {
            this.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    handleHiddenTransactionTemplate = async (id, isHidden) => {
        this.setLoading(true);
        try {
            await axiosInstance.post(`/transaction_templates/hidden/${id}/`, { isHidden });
        } catch (error) {
            this.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    onSetDate = async (elem) => {
        this.selectedTransaction = elem;
        this.setShowModal('setDate', true);
    }

    setDate = async (date) => {
        this.setLoading(true);
        try {
            await axiosInstance.post(`/transactions/set_date/${this.selectedTransaction.id}/`, { date });
            // refetch transactions
            await this.fetchTransactions();
        } catch (error) {
            this.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    onChangeColor = async (elem) => {
        this.selectedTransactionType = elem;
        this.setShowModal('changeColor', true);
    }

    handleChangeColor = async (color) => {
        this.setLoading(true);
        try {
            await axiosInstance.post(`/transaction_types/set_transaction_type_color/${this.selectedTransactionType.id}/`, { color });
            // refetch transactions
            await this.initializePageData();
        } catch (error) {
            this.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }
}

export default new TransactionStore();