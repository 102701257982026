import React, {useEffect, useState} from 'react';
import { Modal, Button } from 'react-bootstrap';
import axiosInstance from "../../../../axiosInstance";
import {logDOM} from "@testing-library/react";
import {observer} from "mobx-react-lite";
import TransactionStore from "../../../../stores/TransactionStore";
import TransactionTemplateList from "./TransactionTemplateList";

const TransactionTemplateDetailModal = observer(({}) => {
    useEffect(() => {
        console.log(TransactionStore.selectedTransactionTemplate)
    } ,[])
    return (
        <Modal show={TransactionStore.showModals.detailTemplate} onHide={() => TransactionStore.setShowModal('detailTemplate', false)}>
            <Modal.Header closeButton>
                <Modal.Title>Transaction Template Detail</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h5>Name</h5>
                <p>{TransactionStore.selectedTransactionTemplate.name}</p>
                <h5>Transaction Type</h5>
                <p>{TransactionStore.selectedTransactionTemplate.transaction_type.name}</p>
                <h5>From </h5>
                <p>{TransactionStore.selectedTransactionTemplate.from_account.name}</p>
                <h5>To </h5>
                <p>{TransactionStore.selectedTransactionTemplate.to_account.name}</p>
                <h5>Formula</h5>
                <p>{TransactionStore.selectedTransactionTemplate.formula}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => TransactionStore.setShowModal('detailTemplate', false)}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
});
export default TransactionTemplateDetailModal;

