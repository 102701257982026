import {makeAutoObservable} from "mobx";
import {axiosInstanceWarehouse} from "../axiosInstance";
import analyticsPage from "../components/Warehouse/Analytics/AnalyticsPage";

class ProductStore {
    ws = undefined;
    wsUrl = `ws://lexti.store/ws/`;
    products = [];
    ozonProductData = {};
    periodStatistics = [];
    calculatedValues = new Map();
    selectedProduct = null;
    showModals = {
        ozonProductData: false,
        ozonStatisticsByMonth: false,
        addProduct: false,
        accountingCalculator: false,
        accountingCalculatorStatistics: false,
    }
    stepSettings = {};
    analyticsLast60Days = [];
    wsActions = {
        fetchPeriodStatistics: 'fetchPeriodStatistics',
        fetchAnalyticsLast60Days: 'fetchAnalyticsLast60Days',
    }
    loading = {
        fetchAnalyticsLast60Days: false,
    }
    constructor() {
        makeAutoObservable(this);
    }

    sendWSMessage(message) {
        if (this.ws && this.ws.readyState === WebSocket.OPEN) {
          this.ws.send(JSON.stringify(message));
        } else {
          this.ws = new WebSocket(this.wsUrl);
          this.addListener(message.ws_return_action);
          this.ws.onopen = () => {
            this.ws.send(JSON.stringify(message));
          }
        }
    }

    closeWSConnect() {
        if (this.ws) {
          this.ws.close();
          console.log("WebSocket connection closed");
        }
      }

    setShowModal = (modal, show) => {
        this.showModals[modal] = show;
    }

    async fetchProducts() {
        try {
            const response = await axiosInstanceWarehouse.get("products/");

            this.products = response.data;
        } catch (error) {
            console.error("Failed to fetch products", error);
        }
    }


    async createProduct(data) {
        try {
            const response = await axiosInstanceWarehouse.post("products/", data);
            this.products.push(response.data);
        } catch (error) {
            console.error("Failed to create product", error);
        }
    }

    async updateProduct(productId, data) {
        try {
            data["id"] = productId;
            const response = await axiosInstanceWarehouse.put(`products/`, data);
            const productIndex = this.products.findIndex(p => p.id === productId);
            if (productIndex !== -1) {
                this.products[productIndex] = response.data;
            }
        } catch (error) {
            console.error("Failed to update product", error);
        }
    }

    async deleteProduct(productId) {
        try {
            await axiosInstanceWarehouse.delete(`products/`, {data: {id: productId}});
            const productIndex = this.products.findIndex(p => p.id === productId);
            if (productIndex !== -1) {
                this.products.splice(productIndex, 1);
            }
        } catch (error) {
            console.error("Failed to delete product", error);
        }
    }

    async fetchOzonProductData(ProductId) {
        try {
            const response = await axiosInstanceWarehouse.get(`products/ozon_product_data/${ProductId}/`);
            this.ozonProductData = response.data;
        } catch (error) {
            console.error("Failed to fetch ozon product data", error);
        }
    }

    async updateOzonProductData(ProductId, data) {
        try {
            const response = await axiosInstanceWarehouse.put(`products/ozon_product_data/${ProductId}/`, data);
            const productIndex = this.products.findIndex(p => p.id === ProductId);
            if (productIndex !== -1) {
                this.products[productIndex].ozon_product_data = response.data;
            }
        } catch (error) {
            console.error("Failed to update ozon product data", error);
        }
    }

    async updateAnalytics() {
        try {
            await axiosInstanceWarehouse.get(`products/update_analytics/`);
            await this.fetchProducts();
        } catch (error) {
            console.error("Failed to update analytics", error);
        }
    }

    setSelectProduct = (product) => {
        this.selectedProduct = product;
    }

    fetchStepSettings = async () => {
        try {
            const response = await axiosInstanceWarehouse.get("/orders/steps_durations/");
            this.stepSettings = response.data;
        } catch (error) {
            console.error("Failed to fetch step settings", error);
        }
    }

    fetchPeriodStatistics(ws_return_action = this.wsActions.fetchPeriodStatistics, start_date, end_date) {
        this.sendWSMessage({
            action: this.wsActions.fetchPeriodStatistics,
            ws_return_action: ws_return_action,
            start_date: start_date,
            end_date: end_date
        });
    }

    addListener(ws_return_action = this.wsActions.fetchPeriodStatistics) {
        this.ws.addEventListener('message', (event) => {
            const message = JSON.parse(event.data);
            if (message.action === ws_return_action) {
                if (ws_return_action === this.wsActions.fetchPeriodStatistics) {
                    this.setPeriodStatistics(message.data);
                    this.ws.close();
                } else if (ws_return_action === this.wsActions.fetchAnalyticsLast60Days) {
                    this.setAnalyticsLast60Days(message.data);
                    this.ws.close();
                }
            }
        });
    }

    setPeriodStatistics(data) {
        this.periodStatistics = data;
    }

    setAnalyticsLast60Days(data) {
        this.analyticsLast60Days = data;
    }

    fetchAnalyticsLast60Days = async () => {
        const date = new Date();

        // from 60 days before now to now (date format DD.MM.YYYY) fetch before success
        await this.fetchPeriodStatistics(this.wsActions.fetchAnalyticsLast60Days, new Date(date.getFullYear(), date.getMonth(), date.getDate() - 60).toLocaleDateString("ru-RU"), date.toLocaleDateString("ru-RU"));
    }

    fetchProductsWithAnalytics = async () => {
        try {
            const response = await axiosInstanceWarehouse.get("products/");
            // wait while analyticsLast60Days will be fetched
            while (this.analyticsLast60Days.length === 0) {
                await new Promise(resolve => setTimeout(resolve, 100));
            }
            // get analytics for each product
            response.data.forEach(product => {
                const analytics = this.analyticsLast60Days.find(analytics => analytics.product_id === product.id);
                if (analytics !== undefined) {
                    product.addAnalyticsLast60Days = analytics;
                } else {
                    product.addAnalyticsLast60Days = {
                        count: -1,
                        product_id: product.id,
                        rate: -1,
                    };
                }
            });

            this.products = response.data;

            this.loading.fetchAnalyticsLast60Days = true;

        } catch (error) {
            console.error("Failed to fetch products", error);
        }
    }

    fetchExportProductsXLSX = async () => {
        try {
            const response = await axiosInstanceWarehouse.get(`/products/export_to_excel/`, {responseType: "blob"});
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'products.xlsx');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Failed to fetch export products xlsx", error);
        }
    }

}

const productStore = new ProductStore();

export default productStore;
