import React, { useEffect } from 'react';
import {Button, Tab, Tabs} from 'react-bootstrap';

import TransactionList from './TransactionList';
import CreateTransactionModal from './CreateTransactionModal';
import TransactionDetailModal from './TransactionDetailModal';
import TransactionTypeList from "./TransactionTypes/TransactionTypeList";
import CreateTransactionTypeModal from "./TransactionTypes/CreateTransactionTypeModal";
import EditTransactionTypeModal from "./TransactionTypes/EditTransactionTypeModal";
import TransactionTypeDetailModal from "./TransactionTypes/TransactionTypeDetailModal";
import ImportButton from "../../Import/ImportButton";
import TransactionTemplateList from "./TransactionsTemplates/TransactionTemplateList";
import CreateTransactionTemplateModal from "./TransactionsTemplates/CreateTransactionTemplateModal";
import UseTemplateModal from "./TransactionsTemplates/UseTemplateModal";
import TransactionTemplateDetailModal from "./TransactionsTemplates/TransactionTemplateDetailModal";
import {observer} from "mobx-react-lite";
import TransactionStore from "../../../stores/TransactionStore";
import TransactionSetDateModal from "./TransactionSetDateModal";
import TransactionTypeChangeColorModal from "./TransactionTypes/TransactionTypeChangeColorModal";

const TransactionsPage = observer(() => {

    // Пример функции для загрузки данных о транзакциях.
    useEffect(() => {
        TransactionStore.initializePageData();
    }, []);


    return (
        <div className="container mt-4">
            <h1>Manage Transactions</h1>
            <Tabs defaultActiveKey="transactions" id="uncontrolled-tab-example">
                <Tab eventKey="transactions" title="Transactions">
                    <h1>Transactions</h1>
                    <div className="mb-3">
                        <Button className="me-2" onClick={() => TransactionStore.setShowModal('create', true)}>
                            Create New Transaction
                        </Button>
                        <Button className="me-2" onClick={() => TransactionStore.setShowModal('useTemplate', true)}>
                            Use Template
                        </Button>
                        <ImportButton uploadUrl="/transactions/import/" />
                    </div>
                    <TransactionList/>

                    <CreateTransactionModal/>
                    <UseTemplateModal/>

                    {TransactionStore.selectedTransaction && (
                        <TransactionDetailModal/>
                    )}
                </Tab>
                <Tab eventKey="transaction-types" title="Transaction Types">
                    <h1>Transaction Types</h1>
                    <div className="mb-3">
                        <Button className="me-2" onClick={() => TransactionStore.setShowModal('createType', true)}>
                            Create New Transaction Type
                        </Button>
                        <ImportButton uploadUrl="/transaction_types/import/" />
                    </div>

                    <TransactionTypeList/>

                    <CreateTransactionTypeModal/>

                    {TransactionStore.selectedTransactionType && (
                        <>
                            <EditTransactionTypeModal/>
                            <TransactionTypeDetailModal/>
                            <TransactionTypeChangeColorModal/>
                        </>
                    )}
                </Tab>
                <Tab eventKey="transaction-templates" title="Transaction Templates">
                    <h1>Transaction Templates</h1>
                    <div className="mb-3">
                        <Button className="me-2" onClick={() => TransactionStore.setShowModal('createTemplate', true)}>
                            Create New Transaction Template
                        </Button>
                    </div>

                    <TransactionTemplateList/>

                    <CreateTransactionTemplateModal/>
                    {TransactionStore.selectedTransactionTemplate && (
                        <>
                            <TransactionTemplateDetailModal/>
                        </>
                    )}
                    {TransactionStore.showModals.setDate && (
                        <TransactionSetDateModal/>
                    )}
                </Tab>
            </Tabs>
        </div>
    );
});

export default TransactionsPage;
