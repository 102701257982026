import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import {useAuth} from "./AuthContext";

export const RegisterForm = () => {
    const [credentials, setCredentials] = useState({ email: '', password: '' });
    const { register } = useAuth();

    const handleChange = (e) => {
        setCredentials({
            ...credentials,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await register(credentials);
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formUsername">
                <Form.Label>Email</Form.Label>
                <Form.Control type="text" placeholder="Enter email" name="email" onChange={handleChange} />
            </Form.Group>

            <Form.Group controlId="formPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" placeholder="Password" name="password" onChange={handleChange} />
            </Form.Group>

            <Button variant="primary" type="submit">
                Register
            </Button>
        </Form>
    );
};
