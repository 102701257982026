import React, { useRef, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axiosInstance from "../../axiosInstance";

const ImportModal = ({ show, handleClose, uploadUrl }) => {
    const fileInputRef = useRef();
    const [fileFormat, setFileFormat] = useState('csv');

    const handleFileUpload = () => {
        const formData = new FormData();
        formData.append('csv_file', fileInputRef.current.files[0]);
        formData.append('format', fileFormat);

        axiosInstance.post(uploadUrl, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
            .then((response) => {
                console.log(response.data);
                handleClose();
            })
            .catch((error) => {
                console.error("An error occurred while uploading the file:", error);
            });
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Import File</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formFile">
                        <Form.Label>Select File</Form.Label>
                        <Form.Control type="file" ref={fileInputRef} />
                    </Form.Group>
                    <Form.Group controlId="formFormat">
                        <Form.Label>Select Format</Form.Label>
                        <Form.Control as="select" value={fileFormat} onChange={(e) => setFileFormat(e.target.value)}>
                            <option value="csv">CSV</option>
                            <option value="xlsx">XLSX</option>
                        </Form.Control>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleFileUpload}>
                    Upload File
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ImportModal;
