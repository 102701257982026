import {ListGroup, Button, Col, Row} from 'react-bootstrap';
import {observer} from "mobx-react-lite";
import TransactionStore from "../../../stores/TransactionStore";

const TransactionList = observer(({  }) => {
    return (
        <ListGroup>
            {TransactionStore.transactions.map((transaction) => (
                <ListGroup.Item key={transaction.id} className="mb-3" style={{ backgroundColor: transaction.transaction_type.color, border: '1px solid #dee2e6' }}>
                    <Row className="mb-2">
                        <Col md={3}>
                            <strong>Type:</strong> {transaction.transaction_type.name}
                        </Col>
                        <Col md={3}>
                            <strong>Amount:</strong> {transaction.amount}
                        </Col>
                        <Col md={3}>
                            <strong>From:</strong> {transaction.from_account.name}
                        </Col>
                        <Col md={3}>
                            <strong>To:</strong> {transaction.to_account.name}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button variant="info" onClick={() => TransactionStore.onDetail(transaction, TransactionStore.types.TRANSACTION)} className="me-2">
                                Details
                            </Button>
                            <Button variant="danger" onClick={() => TransactionStore.handleDelete(transaction, TransactionStore.types.TRANSACTION)} className='me-2'>
                                Delete
                            </Button>
                            <Button variant="warning" onClick={() => TransactionStore.onSetDate(transaction)} className='me-2'>
                                Set Date
                            </Button>
                        </Col>
                    </Row>
                </ListGroup.Item>
            ))}
        </ListGroup>
    );
});

export default TransactionList;
