import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { axiosInstanceWarehouse } from "../../../axiosInstance";
import { observer } from "mobx-react-lite";
import ProductStore from "../../../stores/ProductStore";

const ProductCreateModal = observer(({ }) => {
    const [product, setProduct] = useState({
        internal_name: ''
    });

    const handleFieldChange = (field, value) => {
        setProduct(prevState => ({ ...prevState, [field]: value }));
    }

    const handleSubmit = async () => {
        try {
            await ProductStore.createProduct(product);
            ProductStore.setShowModal('addProduct', false);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Modal
            show={ProductStore.showModals.addProduct}
            onHide={() => ProductStore.setShowModal('addProduct', false)}>
            <Modal.Header closeButton>
                <Modal.Title>Add Product</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="internal_name">
                        <Form.Label>Internal Name</Form.Label>
                        <Form.Control
                            type="text"
                            value={product.internal_name}
                            onChange={(e) => handleFieldChange('internal_name', e.target.value)}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => ProductStore.setShowModal('addProduct', false)}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
});

export default ProductCreateModal;