import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import {observer} from "mobx-react-lite";
import TransactionStore from "../../../../stores/TransactionStore";

const CreateTransactionTypeModal = observer(({  }) => {
    const [name, setName] = useState('');

    const onSubmit = (e) => {
        e.preventDefault();
        TransactionStore.handleCreate({ name }, 'transactionType');
    };

    return (
        <Modal show={TransactionStore.showModals.createType} onHide={() => TransactionStore.setShowModal('createType', false)}>
            <Modal.Header closeButton>
                <Modal.Title>Create Transaction Type</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={onSubmit}>
                    <Form.Group controlId="name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)} />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        Create
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
});
export default CreateTransactionTypeModal;

