import React, {useEffect} from 'react';
import { Modal, Button } from 'react-bootstrap';
import axiosInstance from "../../../axiosInstance";
import {observer} from "mobx-react-lite";
import TransactionStore from "../../../stores/TransactionStore";

const TransactionDetailModal = observer(({ }) => {
    console.log(TransactionStore.selectedTransaction)
    return (
        <Modal show={TransactionStore.showModals.detail} onHide={() => TransactionStore.setShowModal('detail', false)}>
            <Modal.Header closeButton>
                <Modal.Title>Transaction Detail</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p><strong>From:</strong> {TransactionStore.selectedTransaction.from_account.name}</p>
                <p><strong>To:</strong> {TransactionStore.selectedTransaction.to_account.name}</p>
                <p><strong>Amount:</strong> ${TransactionStore.selectedTransaction.amount}</p>
                <p><strong>Description:</strong> {TransactionStore.selectedTransaction.description}</p>
                <p><strong>Type:</strong> {TransactionStore.selectedTransaction.transaction_type.name}</p>
                <p><strong>Created At:</strong> {TransactionStore.selectedTransaction.created_at}</p>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => TransactionStore.setShowModal('detail', false)}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
});

export default TransactionDetailModal;

