import React from 'react';
import {Button} from 'react-bootstrap';
import {observer} from "mobx-react-lite";
import AccountStore from "../../../stores/AccountStore";

const SubAccountRow = observer(({ subAccount, parentAccount }) => {
    return (
        <tr key={subAccount.id} className="table-active" >
            <td style={{
                textAlign: "right"
            }}>{subAccount.number}</td>
            <td>{subAccount.name}</td>
            <td>
                {subAccount.balance}
            </td>
            <td>{subAccount.type}</td>
            <td>
                <Button variant="info" onClick={() => AccountStore.handleSelectAccount(subAccount)}>
                    {/* <YourIconComponentForDetails /> */}
                    Details
                </Button>
                {' '}
                <Button variant="warning" onClick={() => AccountStore.editSubAccount(subAccount, parentAccount)}>
                    {/* <YourIconComponentForEdit /> */}
                    Edit
                </Button>
                {' '}
                <Button variant="danger" onClick={() => AccountStore.handleDeleteAccount(subAccount.id, parentAccount)}>
                    {/* <YourIconComponentForDelete /> */}
                    Delete
                </Button>
            </td>
        </tr>
    );
});

export default SubAccountRow;
