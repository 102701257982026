import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import axiosInstance from "../../../axiosInstance";

const ProfitLossStatement = () => {
    const [profitLossParams, setProfitLossParams] = useState({
        group_by_type: 'no',
        date_aggregation: 'month',
        start_date: '',
        end_date: '',
    });

    const handleDownloadProfitLoss = async () => {
        try {
            const response = await axiosInstance.get('/financial_statements/profit_loss_statement/', {
                params: profitLossParams,
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'ProfitLossStatement.xlsx');
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error("An error occurred while downloading the file: ", error);
        }
    };

    return (
        <>
            <h2>Profit & Loss Statement</h2>
            <Form>
                <Form.Check
                    type="switch"
                    id="group_by_type_switch"
                    label="Not Zero Balance"
                    onChange={() => setProfitLossParams({
                        ...profitLossParams,
                        group_by_type: profitLossParams.group_by_type === 'yes' ? 'no' : 'yes'
                    })}
                />
                <Form.Group controlId="startDate">
                    <Form.Label>Start Date</Form.Label>
                    <Form.Control
                        type="date"
                        value={profitLossParams.start_date}
                        onChange={(e) => setProfitLossParams({
                            ...profitLossParams,
                            start_date: e.target.value
                        })}
                    />
                </Form.Group>
                <Form.Group controlId="endDate">
                    <Form.Label>End Date</Form.Label>
                    <Form.Control
                        type="date"
                        value={profitLossParams.end_date}
                        onChange={(e) => setProfitLossParams({
                            ...profitLossParams,
                            end_date: e.target.value
                        })}
                    />
                </Form.Group>
                <Form.Label>Date Aggregation</Form.Label>
                <Form.Select
                    id="date_aggregation"
                    value={profitLossParams.date_aggregation}
                    onChange={(e) => setProfitLossParams({
                        ...profitLossParams,
                        date_aggregation: e.target.value
                    })}
                >
                    <option value="month">Month</option>
                    <option value="week">Week</option>
                </Form.Select>
            </Form>
            <Button onClick={handleDownloadProfitLoss}>
                Download Profit & Loss Statement
            </Button>
        </>
    );
};

export default ProfitLossStatement;
